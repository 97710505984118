export enum Experiment {
  CleanUpBobWhenMAIsDeleted = 'specs.membersArea.CleanUpBobWhenMAIsDeleted',
  UseNewBlockedEmptyState = 'specs.profileCardOOI.useNewBlockedEmptyState',
  UseRoutesFromGlobalController = 'specs.membersArea.routesFromGlobalController',
  DoNotInitiallyInstallProfileCard = 'specs.membersArea.DoNotInitiallyInstallProfileCard',
  WaitForMemberPageTransaction = 'specs.membersArea.WaitForMemberPageTransaction',
  DontWaitForBoBController = 'specs.membersArea.DontWaitForBoBController',
  UseSyncDeleteActions = 'specs.membersAreaV2.UseSyncDeleteActions',
  SyncDataWithMenuItems = 'specs.membersAreaV2.SyncDataWithMenuItems',
  RemoveUpdatingBoBController = 'specs.responsive-editor.NoMeasureInstall',
  UseAppDataForRoutes = 'specs.membersArea.UseAppDataForRoutes',
  DisableRouteIntegrityCheck = 'specs.membersArea.DisableRouteIntegrityCheck',
  FasterMemberFetching = 'specs.membersAreaV2.fasterMemberFetching',
  SplitInstallation = 'specs.membersArea.SplitInstallation',
  InstalledWidgetsFromRoutes = 'specs.membersArea.installedWidgetsFromRoutes',
  AddWidgetsPluginsResilience = 'specs.membersArea.AddWidgetsPluginsResilience',
  VerticalDeletionRemoveRefreshApp = 'specs.profilePageBoB.VerticalDeletionRemoveRefreshApp',
}
