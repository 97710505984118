import { membersAreaMenuItemsClick } from '@wix/bi-logger-members-app-uou/v2';
import {
  getPageIdAndAppDefIdFromWidgetId,
  WidgetId,
} from '@wix/members-area-app-definitions';
import { ITEM_TYPES } from '@wix/advanced-seo-utils';

import {
  ContextProps,
  ContextServices,
  ErrorStateService,
  FlowAPI,
  MultiStateBoxService,
  RouteData,
  State,
  WixCodeApi,
} from '../../../types';
import { getIsTabAccessibleWithUserRoles } from '../utils';
import { emitTabChangedEventToPlugins } from './widget-plugins';
import { Experiment } from '../../../constants';

type NavigationRulesProps = Pick<ContextServices, 'membersService'> &
  Pick<ContextProps, 'state'> & {
    routeData: RouteData;
    isEditor: boolean;
  };

export const redirectToLogin = async (
  multiStateBoxService: MultiStateBoxService,
  wixCodeApi: WixCodeApi,
  errorStateService: ErrorStateService,
  shouldUseErrorStateService: boolean,
) => {
  await multiStateBoxService.showErrorAppState();
  if (shouldUseErrorStateService) {
    errorStateService.resetToNotFoundContent();
  }
  // Does not work in SSR, renders login in CSR
  // Do not add await, it will stop execution of the rest of the code
  wixCodeApi.user.promptLogin({});
};

export const redirectTo404 = (
  multiStateBoxService: MultiStateBoxService,
  errorStateService: ErrorStateService,
  shouldUseErrorStateService: boolean,
) => {
  multiStateBoxService.showErrorAppState();
  if (shouldUseErrorStateService) {
    errorStateService.resetToNotFoundContent();
  }
};

const getNavigationRules = ({
  state,
  routeData,
  membersService,
  isEditor,
}: NavigationRulesProps) => {
  const { stateId, slugOrId, isStatePrivate, visibleForRoles } = routeData;
  const { currentMember, viewedMember } = state.members;

  const isViewedMemberCurrentMember =
    membersService.isViewedMemberCurrentMember(slugOrId, currentMember);
  const isTabInaccessible = !getIsTabAccessibleWithUserRoles(
    state.viewedMemberRoles,
    visibleForRoles,
  );

  const isRouteDataMissing = !stateId;
  const isRouteDataOrViewedMemberMissing = isRouteDataMissing || !viewedMember;
  const isPrivateAndNotViewed = isStatePrivate && !isViewedMemberCurrentMember;
  const shouldRedirectPublicTabToLogin =
    !currentMember && !isStatePrivate && !slugOrId;
  const shouldRedirectPrivateTabToLogin =
    !isEditor && !isRouteDataMissing && isStatePrivate && !currentMember;

  const shouldRedirectToLogin =
    shouldRedirectPrivateTabToLogin || shouldRedirectPublicTabToLogin;
  const shouldRedirectTo404 =
    !isEditor &&
    (isRouteDataOrViewedMemberMissing ||
      isTabInaccessible ||
      isPrivateAndNotViewed);

  return { shouldRedirectToLogin, shouldRedirectTo404 };
};

const emitMembersAreaMenuItemClickBIEvent = (
  flowAPI: FlowAPI,
  routeData: RouteData,
) => {
  const { pageId, appDefId } = getPageIdAndAppDefIdFromWidgetId(
    routeData.visibleWidgetId,
  );
  flowAPI.bi?.report(
    membersAreaMenuItemsClick({
      pageName: pageId,
      originAppId: appDefId,
    }),
  );
};

const showTab = async (
  {
    flowAPI,
    wixCodeApi,
    state,
  }: { flowAPI: FlowAPI; wixCodeApi: WixCodeApi; state: State },
  {
    multiStateBoxService,
    routeDataService,
    widgetPluginHostService,
  }: Pick<
    ContextServices,
    'multiStateBoxService' | 'routeDataService' | 'widgetPluginHostService'
  >,
) => {
  const routeData = routeDataService.getRouteData();

  // During installation, widget data is not yet available
  if (!routeData.stateId) {
    return;
  }

  await widgetPluginHostService.membersAreaWidgetReady();
  await multiStateBoxService.showSlotStateById(routeData.stateId);

  await emitTabChangedEventToPlugins(state, {
    widgetPluginHostService,
    routeDataService,
  });

  if (
    !routeData.isStatePrivate &&
    routeData.visibleWidgetId !== WidgetId.About
  ) {
    wixCodeApi.seo.renderSEOTags({
      itemType: ITEM_TYPES.MEMBERS_AREA_PROFILE_TABS,
      itemData: {},
    });
  }

  emitMembersAreaMenuItemClickBIEvent(flowAPI, routeData);
};

export const handleNavigation = (
  {
    wixCodeApi,
    state,
    flowAPI,
  }: Pick<ContextProps, 'wixCodeApi' | 'state' | 'flowAPI'>,
  {
    multiStateBoxService,
    errorStateService,
    routeDataService,
    membersService,
    widgetPluginHostService,
    menuService,
  }: Pick<
    ContextServices,
    | 'membersService'
    | 'multiStateBoxService'
    | 'errorStateService'
    | 'routeDataService'
    | 'widgetPluginHostService'
    | 'menuService'
  >,
) => {
  const routeData = routeDataService.getRouteData();
  const { shouldRedirectToLogin, shouldRedirectTo404 } = getNavigationRules({
    state,
    membersService,
    routeData,
    isEditor: flowAPI.environment.isEditor,
  });

  if (shouldRedirectToLogin) {
    const shouldUseErrorStateService = flowAPI.experiments.enabled(
      Experiment.UseNewBlockedEmptyState,
    );
    return redirectToLogin(
      multiStateBoxService,
      wixCodeApi,
      errorStateService,
      shouldUseErrorStateService,
    );
  }

  if (shouldRedirectTo404) {
    const shouldUseErrorStateService = flowAPI.experiments.enabled(
      Experiment.UseNewBlockedEmptyState,
    );
    return redirectTo404(
      multiStateBoxService,
      errorStateService,
      shouldUseErrorStateService,
    );
  }

  menuService.selectMenuItem(routeData.path);

  return showTab(
    { flowAPI, wixCodeApi, state },
    {
      widgetPluginHostService,
      routeDataService,
      multiStateBoxService,
    },
  );
};
